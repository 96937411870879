import axios from '@axios'
import moment from 'moment-timezone';

export default {
  namespaced: true,
  state: {

    actionData: null,

    mode: 'day',

    titleCard: {
      state: null,
      data: null,
    },

    chartCard: {
      state: null,
      data: null,
    },

    tableCard: {
      state: null,
      data: null,
    },

  },
  getters: {},
  mutations: {

    UPDATE_TITLE(state, payload) {
      state.titleCard = payload
    },

    UPDATE_CHART(state, payload) {
      state.chartCard = payload
    },

    UPDATE_TABLE(state, payload) {
      state.tableCard = { ...state.tableCard, ...payload }
    },

    UPDATE_ACTION_DATA(state, payload) {
      state.actionData = payload
    },

    RESET_DATA(state, payload) {
      state.actionData = null
      state.titleCard = {
        state: null,
        data: null,
      }
      state.chartCard = {
        state: null,
        data: null,
      }
      state.tableCard = {
        state: null,
        data: null,
      }
    },

    UPDATE_MODE(state, payload) {
      state.mode = payload
    },

  },
  actions: {
    fetchTitle({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(
          `/reports/consumption/consumer-type-wise/title/${state.mode}/${payload.consumerType}/${payload.startTimestamp}/${payload.endTimestamp}`
        )
          .then(({ data: { results } }) => {
            resolve(results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchChart({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`/reports/consumption/consumer-type-wise/chart/${state.mode}/${payload.consumerType}/${payload.startTimestamp}/${payload.endTimestamp}`)
          .then(({ data: { results } }) => {
            resolve(results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTable({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(
          `/reports/consumption/consumer-type-wise/table/${state.mode}/${payload.consumerType}/${payload.startTimestamp}/${payload.endTimestamp}/paginate`, {
          params: payload.queryParams
        }
        )
          .then(({ data: { results } }) => {
            resolve(results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },


    openPDF({ state }, payload) {
      const url = `${axios.defaults.baseURL}/reports/consumption/consumer-type-wise/pdf/${state.mode}/${payload.consumerType}/${payload.startTimestamp}/${payload.endTimestamp}`
      axios.defaults.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then((response) => {
            delete axios.defaults.responseType;
            resolve(response)
          })
          .catch(error => {
            delete axios.defaults.responseType;
            reject(error)
          })
      })
    },
    downloadExcel({ state }, payload) {
      const url = `${axios.defaults.baseURL}/reports/consumption/consumer-type-wise/excel/${state.mode}/${payload.consumerType}/${payload.startTimestamp}/${payload.endTimestamp}`
      axios.defaults.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then((response) => {
            delete axios.defaults.responseType;
            resolve(response)
          })
          .catch(error => {
            delete axios.defaults.responseType;
            reject(error)
          })
      })
    },

    setTitle({ commit }, payload) {
      commit('UPDATE_TITLE', payload)
    },
    setChart({ commit }, payload) {
      commit('UPDATE_CHART', payload)
    },
    setTable({ commit }, payload) {
      commit('UPDATE_TABLE', payload)
    },
    setActionData({ commit }, payload) {
      commit('UPDATE_ACTION_DATA', payload)
    },
    resetData({ commit }) {
      commit('RESET_DATA', null)
    },
    setMode({ commit }, payload) {
      commit('UPDATE_MODE', payload)
    },
  },
}
